/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Nav from "../Navbar/Nav";
import Video from "./Video";
import Overlay from "./Overlay";
import Info from "./Info";
import useWindowDimensions from "../../hook/useWindowDimensions";

const BGImg_1 = "/./images/training.jpg";
const BGImg = "/./images/gof_header_bg.jpeg";

const Main = ({
  sectionBgImg,
  area,
  subtitle,
  description,
  activePage,
  showBGVideo,
  hideInfo,
  onlyNavbar,
  address,
  contactNumber1,
  contactNumber2,
}) => {
  const { height, width } = useWindowDimensions();

  return (
    <section
      css={styles}
      style={{
        height: onlyNavbar ? "auto" : hideInfo ? "auto" : width > 500 ? '90vh' : "58vh",
        // backgroundImage: showBGVideo ? `url(${BGImg})` : `url(${sectionBgImg || BGImg_1})`,
        backgroundImage: showBGVideo ? `` : `url(${sectionBgImg || BGImg_1})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        // backgroundPosition: "center center",
      }}
      className="main"
      id="home"
    >
      {/* {hideInfo ? (
        <img
          src={BGImg}
          alt="Game on fitness Hero Image"
          style={{ width: "100%" }}
        />
      ) : (
        <></>
      )} */}

      {hideInfo ? <></> : <Overlay />}
      <Nav activePage={activePage} onlyNavbar />
      {hideInfo ? (
        <></>
      ) : (
        <Info
          area={area}
          subtitle={subtitle}
          description={description}
          address={address}
          contactNumber1={contactNumber1}
          contactNumber2={contactNumber2}
        />
      )}

      {showBGVideo ? <Video /> : null}
    </section>
  );
};

const styles = css`
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 500px) {
    // min-height: 70vh;
    // max-height: 70vh;
    /* min-height: 58vh;
    max-height: 58vh; */
    background-size: cover;
  }
`;

export default Main;
