/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Container from "../GlobalComponents/Container";
import Map from "./Map";
import Form from "./Form";

const Contact = ({ mapsrc, address, contactNumber1, contactNumber2 }) => (
  <section css={styles} className="contact" id="contact">
    {address && contactNumber1 && contactNumber2 ? (
      <>
        {/* <h2>
          OUR <span>ADDRESS</span>
        </h2> */}
        {/* <Icon /> */}
        {/* <p className="address">
          {address} <br /> Contact Number: {contactNumber1} | {contactNumber2}
        </p> */}
        <Container>
          <Map mapsrc={mapsrc} />
          {/* <Form /> */}
        </Container>{" "}
      </>
    ) : null}
  </section>
);

const styles = css`
  width: 100%;
  text-align: center;
  padding 0 0 40px 0;
  h2 {
    color: #232d39;
    font-weight: 900;
    font-size: 26px;
    letter-spacing: 1.3px;
    line-height: 34px;
    margin-bottom: 20px;
    span {
      color: #fe640e;
    }
  }
  .address {
    text-align:center;
    color: #7a7a7a;
    font-size: 16px;
    line-height: 2;
    margin: 0 40px 30px 40px;
  }
  .container {
    max-width: 100%;
    display: flex;
  }
  footer {
    text-align: center;
    padding: 36px 0;
    p {
      font-size: 15px;
      color: #232d39;
      a {
        text-decoration: none;
        color: #ed563b;
        font-weight: 500;
        &:hover {
          color: #ed563b;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .container {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  
  @media (max-width: 450px) {
    h2 {
      font-size: 23px;
    }
  }
`;

export default Contact;
