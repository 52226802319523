/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import Button from "../GlobalComponents/Button";
import GymOverlayBg from "../Image/gymOverlayBg.jpg";

const Member = ({ text, onBtnClick }) => (
  <section css={styles} className="member">
    <h2>
      DON’T <span>THINK</span>, BEGIN <span>TODAY</span>!
    </h2>

    <p>Get Access To All The Services in Just One Membership.</p>
    <p>Contact us on email:</p>
    <p>info@gameonfitness.in</p>

    <Button handleClick={onBtnClick} text="BOOK FREE CONSULTATION" />
  </section>
);

const styles = css`
  width: 100%;
  padding: 120px 20px;
  text-align: center;
  background: url("${GymOverlayBg}") no-repeat center/cover;
  h2 {
    color: #fff;
    font-weight: 900;
    font-size: 36px;
    letter-spacing: 1.3px;
    line-height: 34px;
    margin-bottom: 15px;
    span {
      color: #fe640e;
    }
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 34px;
  }
  @media (max-width: 850px) {
    h2 {
      font-size: 30px;
    }
    p {
      padding: 0 20px;
      br {
        display: none;
      }
    }
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 23px;
    }
    p {
      font-size: 14px;
    }
  }

`;

export default Member;
