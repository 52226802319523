/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const GymProgram = () => {
  return (
    <div css={styles} className="gymProgram" id="branches">
      <h2>
        {/* Get Access to All The <span>Services</span> In Just One Membership */}
        EXPLORE VARIETY OF <span>FORMATS</span>
      </h2>
      {/* <Icon /> */}

      {/* <Container>
        {SERVICES_LIST.map((item, index) => {
          return <ServiceCard key={item.title + index} title={item.title} />;
        })}
      </Container> */}

      <img src="/./images/services-img.jpg" alt="Services" />
    </div>
  );
};

const styles = css`
  /* width: 100%; */
  // min-height: 100vh;
  text-align: center;
  // padding: 0 0 30px 0;
  /* margin: 25px 0 40px 0; */
  border-radius: 10px;
  padding: 25px 0 0 0;
  margin: 35px 25px 10px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  h2 {
    color: #232d39;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    margin: 10px 0 20px;
    span {
      color: #fe640e;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 0;
  }

  img {
    width: 100%;
  }

  @media (max-width: 700px) {
    .container {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  @media (min-width: 701px) and (max-width: 1100px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 450px) {
    padding: 10px 0 0 0;

    h2 {
      font-size: 23px;
      padding: 0 18px;
      margin: 10px 0;
    }
  }
`;

export default GymProgram;
