// Branch Details

export const BRANCH_DETAILS = [
  {
    title: "Sarjapur Road",
    desc: "No 648 E 3rd floor, next to more mega store, Marathahalli - Sarjapur Main road, gate, Bellandur, Bengaluru, Karnataka 560035",
    link: "/sarjapura",
    color: "#ed563b",
    bgImg: "/./images/branchs/sarjapur-branch.jpeg",
  },
  {
    title: "Arekere",
    desc: "Above Poorvika Mobiles, near Sai Baba temple, Arekere, Bengaluru - 560076",
    link: "/arekere",
    color: "#ef674f",
    bgImg: "/./images/branchs/arekere-branch.jpeg",
  },
  {
    title: "Vijaya Bank Layout",
    desc: "#8883, 886, 3rd Floor, Bannerghatta Main Rd, Vijaya Bank Layout, Bilekahalli, Bengaluru, Karnataka 560076",
    link: "/vijaya-bank-layout",
    color: "#f17862",
    bgImg: "/./images/branchs/vijaya-bank-layout-branch.jpeg",
  },
  {
    title: "BTM Layout - 1",
    desc: "#24, 1st Floor, 3rd Cross, 18th Main Rd, Opposite Canara Bank, BTM 2nd Stage, Bengaluru, Karnataka 560076.",
    link: "/btm-layout-1",
    color: "#d54d35",
    bgImg: "/./images/branchs/btm-1-branch.jpeg",
  },
  {
    title: "BTM Layout - 2",
    desc: "BTM 2th Stage, 7th Main, Next to Devi Bakery, Bengaluru, Karnataka 560076.",
    link: "/btm-layout-2",
    color: "#be452f",
    bgImg: "/./images/branchs/btm-2-stage-branch.jpeg",
  },
  {
    title: "Wilson Garden",
    desc: "1st Floor, No.376, TV Plaza 21, 6th Cross Rd, opp. to traffic police station, Vinayaka Nagar, NGO Colony, Wilson Garden, Bengaluru, Karnataka 560027",
    link: "/wilson-garden",
    color: "#a63c29",
    bgImg: "/./images/branchs/wilson-garden-branch.jpeg",
  },
  {
    title: "JP Nagar",
    desc: "1st Floor, #13A, Above Poorvika Mobiles, Kothnur Main Rd, RBI Layout, JP Nagar 7th Phase, B-78, Bengaluru, Karnataka 560078",
    link: "/jp-nagar",
    color: "#ed563b",
    bgImg: "/./images/branchs/jpnagar_branch.jpeg",
  },
  {
    title: "Akshaynagar",
    desc: "Above Reliance Smart, near DLF, Akshayanagar, Bengaluru - 560068",
    link: "/akshayanagar",
    color: "#ef674f",
    bgImg: "/./images/trainers/akshayanagar/akshayanagar_img1.jpeg",
  },
];

// Servies

export const SERVICES_LIST = [
  { title: "Aerobic Exercises" },
  { title: "Strength Training " },
  { title: "Weight Training " },
  { title: "Cross Fit" },
  { title: "Endurance Training" },
  { title: "Group Classes" },
  { title: "Zumba" },
  { title: "Yoga" },
  { title: "HIIT" },
  { title: "Tabata & Abs" },
  { title: "Nutrition Consultation" },
  { title: "Weight Loss Program" },
  { title: "Weight Gain Program" },
  { title: "Body Toning " },
  { title: "Steam and Shower" },
];

// export const BTM_LAYOUT_1 = {
//     area:'',
//     subtitle:'',
//     description:'',
//     address:'',
//     contactNumber1: '',
//     contactNumber2: '',
//     businessHours: [],
//     businessHours: [],
//     trainings: []
// }

export const BTM_LAYOUT_1 = {
  bgImg: "/./images/branchs/btm1_bg_img.jpeg",
  mapsrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.05647079260947!2d77.61085612800211!3d12.913921350311655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae157ee3435e77%3A0x8b0e44dc3d708cfe!2sGame%20On%20Fitness%20-1%20BTM%20Layout!5e0!3m2!1sen!2sin!4v1665324066161!5m2!1sen!2sin",
  area: "BTM Layout - 1",
  subtitle:
    "Premium Fitness Centre & Gym in BTM Layout, Providing With Steam, Shower & Locker",
  description: "WE TRAIN YOU SMARTER, NOT HARDER!",
  address:
    "Opp to Canara Bank, 18th Main Road BTM 2nd Stage, Bengaluru - 560076",
  contactNumber1: "8722299457",
  contactNumber2: "7349636333",
  businessHours: [
    { id: 1, day: "Monday", timings: "5:30 AM – 11:00 PM" },
    { id: 2, day: "Tuesday", timings: "5:30 AM – 11:00 PM" },
    { id: 3, day: "Wednesday", timings: "5:30 AM – 11:00 PM" },
    { id: 4, day: "Thursday", timings: "5:30 AM – 11:00 PM" },
    { id: 5, day: "Friday", timings: "5:30 AM – 11:00 PM" },
    { id: 6, day: "Saturday", timings: "5:30 AM – 11:00 PM" },
    { id: 7, day: "Sunday", timings: "6:00 AM – 10:00 PM" },
  ],
  trainings: [
    {
      id: 1,
      title: "FITNESS GYM",
      details: [
        " WEIGHT LOSS PROGRAM",
        "WEIGHT GAIN PROGRAM",
        "BODY TONING",
        "CARDIO",
        "WEIGHT & STRENGTH",
        "KETTEL BELL",
        "BATTEL ROPE",
      ],
    },
    {
      id: 2,
      title: "FACILITY",
      details: [
        "BMI CHECK UP",
        "WORKOUT PLAN",
        "DIET PLAN",
        "SHOWERS",
        "LOCKERS",
        "STEAM",
      ],
    },
  ],
  trainers: [
    {
      id: "01",
      img: "/./images/trainers/btm1/btm1_trainer_1.jpeg",
      name: "Vijay",
      role: "Mobility and Strength training",
      certifications: "ACE | CPR",
      experience: "3+ Years",
    },
    {
      id: "02",
      img: "/./images/trainers/btm1/btm1_trainer_2.jpeg",
      name: "Rahul",
      role: "Muscle gain, S & C",
      certifications: "IACET | AED | NSQF Level 4",
      experience: "3 Years",
    },
  ],
  galleryImgs: [
    {
      imgUrl: "/./images/trainers/btm1/btm1_img1.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm1/btm1_img2.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm1/btm1_img3.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm1/btm1_img4.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm1/btm1_img5.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm1/btm1_img6.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm1/btm1_img7.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm1/btm1_img8.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm1/btm1_img9.jpeg",
    },
  ],
};

export const SARJAPURA = {
  bgImg: "/./images/branchs/sarjapur-bg-img.jpeg",
  mapsrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d972.2137533454369!2d77.67258544198819!3d12.917038662845538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae135e1858bdb5%3A0x63ddb8e1305b97f4!2sGame%20On%20Fitness%20Premium%20Club%20-%20Sarjapur%20Road%20-%20Bellandur%20gate!5e0!3m2!1sen!2sin!4v1665323712326!5m2!1sen!2sin",
  area: "SARJAPUR ROAD",
  subtitle:
    "Premium Fitness Centre & Gym in SARJAPURA, Providing Group Classes with Steam, Shower & Locker",
  description:
    "If you want something you've never had, you must be willing to do something you've never done",
  address:
    "3rd floor above Baby Store, opp to Divyasree Elan, next to More Mega Store, Bellandur gate, Sarjapur Main Road, Bengaluru - 560035",
  contactNumber1: "8618086458",
  contactNumber2: "959183289",
  businessHours: [
    { id: 1, day: "Monday", timings: "5:00 AM – 11:00 PM" },
    { id: 2, day: "Tuesday", timings: "5:00 AM – 11:00 PM" },
    { id: 3, day: "Wednesday", timings: "5:00 AM – 11:00 PM" },
    { id: 4, day: "Thursday", timings: "5:00 AM – 11:00 PM" },
    { id: 5, day: "Friday", timings: "5:00 AM – 11:00 PM" },
    { id: 6, day: "Saturday", timings: "5:00 AM – 11:00 PM" },
    { id: 7, day: "Sunday", timings: "7:00 AM – 10:00 PM" },
  ],
  trainings: [
    {
      id: 1,
      title: "FITNESS GYM",
      details: [
        " WEIGHT LOSS PROGRAM",
        "WEIGHT GAIN PROGRAM",
        "BODY TONING",
        "CARDIO",
        "WEIGHT & STRENGTH",
        "KETTEL BELL",
        "BATTEL ROPE",
      ],
    },
    {
      id: 2,
      title: "GROUP CLASSES",
      details: [
        "ZUMBA",
        "YOGA",
        "POWER YOGA",
        "BOLLY-DANCE",
        "TAE BO",
        "TABATA",
        "BODY COMBAT",
        "CROSSFIT",
        "CIRCUIT TRAINING",
        "ABT",
        "DANCE FITNESS",
      ],
    },
    {
      id: 3,
      title: "FACILITY",
      details: [
        "BMI CHECK UP",
        "WORKOUT PLAN",
        "DIET PLAN",
        "SHOWERS",
        "LOCKERS",
        "STEAM",
      ],
    },
  ],
  trainers: [
    {
      id: "01",
      img: "/./images/trainers/sarjapur/sarjapur-road-trainer1.jpeg",
      name: "Zameer",
      role: "Weight loss",
      certifications: "PROTON LEVEL 1 | CPR",
      experience: "8 Years",
    },
    {
      id: "02",
      img: "/./images/trainers/sarjapur/sarjapur-road-trainer2.jpeg",
      name: "Kittu",
      role: "Functional training",
      certifications: "HST | CPT | CHI | FIRST AID",
      experience: "3+ Years",
    },
    {
      id: "03",
      img: "/./images/trainers/sarjapur/sarjapur-road-trainer3.jpeg",
      name: "Chetan",
      role: "S & C Muscle gain",
      certifications: "NSDC | PASC",
      experience: "3+ Years",
    },
  ],

  galleryImgs: [
    {
      imgUrl: "/./images/trainers/sarjapur/sarjapur-img1.jpg",
    },
    {
      imgUrl: "/./images/trainers/sarjapur/sarjapur-img2.jpg",
    },
    {
      imgUrl: "/./images/trainers/sarjapur/sarjapur-img3.jpg",
    },
    {
      imgUrl: "/./images/trainers/sarjapur/sarjapur-img4.jpg",
    },
    {
      imgUrl: "/./images/trainers/sarjapur/sarjapur-img5.jpg",
    },
    {
      imgUrl: "/./images/trainers/sarjapur/sarjapur-img6.jpg",
    },
    {
      imgUrl: "/./images/trainers/sarjapur/sarjapur-img7.jpg",
    },
    {
      imgUrl: "/./images/trainers/sarjapur/sarjapur-img8.jpg",
    },
  ],

  ytVideoLink: "https://www.youtube.com/embed/4kevhozw_UU",
};

export const BTM_LAYOUT_2 = {
  bgImg: "/./images/branchs/btm2_bg_img.jpeg",
  mapsrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.05757478298105!2d77.60623028059312!3d12.912786283636176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15f046b9e201%3A0xce69228b525912f4!2sGame%20On%20Fitness%20Premium%20Club%20-%20BTM%20Layout!5e0!3m2!1sen!2sin!4v1665324007756!5m2!1sen!2sin",
  area: "BTM Layout - 2",
  subtitle:
    "Premium Fitness Centre & Gym in BTM Layout, Providing With Steam, Shower & Locker",
  description: "DAY 1 OR 1 DAY YOU DECIDE!",
  address: "Above Indian Bank, 7th Main Road BTM 2nd Stage, Bengaluru - 560076",
  contactNumber1: "8951028839",
  contactNumber2: "89510 28839",
  businessHours: [
    { id: 1, day: "Monday", timings: "5:00 AM – 11:00 PM" },
    { id: 2, day: "Tuesday", timings: "5:00 AM – 11:00 PM" },
    { id: 3, day: "Wednesday", timings: "5:00 AM – 11:00 PM" },
    { id: 4, day: "Thursday", timings: "5:00 AM – 11:00 PM" },
    { id: 5, day: "Friday", timings: "5:00 AM – 11:00 PM" },
    { id: 6, day: "Saturday", timings: "5:00 AM – 11:00 PM" },
    { id: 7, day: "Sunday", timings: "7:00 AM – 09:00 PM" },
  ],
  trainings: [
    {
      id: 1,
      title: "FITNESS GYM",
      details: [
        " WEIGHT LOSS PROGRAM",
        "WEIGHT GAIN PROGRAM",
        "BODY TONING",
        "CARDIO",
        "WEIGHT & STRENGTH",
        "VIPER TRAINIG",
        "KETTEL BELL",
        "BATTEL ROPE",
      ],
    },
    {
      id: 2,
      title: "FACILITY",
      details: [
        "BMI CHECK UP",
        "WORKOUT PLAN",
        "DIET PLAN",
        "SHOWERS",
        "LOCKERS",
        "STEAM",
      ],
    },
  ],
  trainers: [
    {
      id: "01",
      img: "/./images/trainers/btm2/btm2_trainer_1.jpeg",
      name: "Wafir davood",
      role: "Mobility and Strength training",
      certifications: "ACE | CPR",
      experience: "3+ Years",
    },
    {
      id: "02",
      img: "/./images/trainers/btm2/btm2_trainer_2.jpeg",
      name: "Deekshith M",
      role: "Muscle gain, S & C",
      certifications: "IACET | AED | NSQF Level 4",
      experience: "3 Years",
    },
    {
      id: "03",
      img: "/./images/trainers/btm2/btm2_trainer_3.jpeg",
      name: "Rahul",
      role: "Weight loss, S & C",
      certifications: "CPR | AED",
      experience: "2 Years",
    },
  ],
  galleryImgs: [
    {
      imgUrl: "/./images/trainers/btm2/btm2_img1.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm2/btm2_img2.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm2/btm2_img3.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm2/btm2_img4.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm2/btm2_img5.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm2/btm2_img6.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm2/btm2_img7.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm2/btm2_img8.jpeg",
    },
    {
      imgUrl: "/./images/trainers/btm2/btm2_img9.jpeg",
    },
  ],
};

export const AREKERE = {
  bgImg: "/./images/branchs/arekere_bg_img.jpeg",
  mapsrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62230.13553357135!2d77.54426543125004!3d12.883045899999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1550e8538c63%3A0x451ec559cc3ef21c!2sGame%20On%20Fitness%20Arekere!5e0!3m2!1sen!2sin!4v1665323861809!5m2!1sen!2sin",
  area: "AREKERE",
  subtitle:
    "Premium Fitness Centre & Gym in AREKERE, Providing Group Classes With Steam, Shower & Locker",
  description:
    "IT’S ALL ABOUT WHAT YOU CAN ACHIVE EMPOWER YOURSELF TO MAKE THE CHANGE YOU NEED TO MAKE",
  address:
    "Above Poorvika Mobiles, near Sai Baba temple, Arekere, Bengaluru - 560076",
  contactNumber1: "8861737392",
  contactNumber2: "8861737392",
  businessHours: [
    { id: 1, day: "Monday", timings: "5:00 AM – 11:00 PM" },
    { id: 2, day: "Tuesday", timings: "5:00 AM – 11:00 PM" },
    { id: 3, day: "Wednesday", timings: "5:00 AM – 11:00 PM" },
    { id: 4, day: "Thursday", timings: "5:00 AM – 11:00 PM" },
    { id: 5, day: "Friday", timings: "5:00 AM – 11:00 PM" },
    { id: 6, day: "Saturday", timings: "5:00 AM – 11:00 PM" },
    { id: 7, day: "Sunday", timings: "7:00 AM – 09:00 PM" },
  ],
  trainings: [
    {
      id: 1,
      title: "FITNESS GYM",
      details: [
        "WEIGHT LOSS PROGRAM",
        "WEIGHT GAIN PROGRAM",
        "BODY TONING",
        "CARDIO",
        "WEIGHT & STRENGTH",
        "VIPER TRAINIG",
        "KETTEL BELL",
        "BATTEL ROPE",
      ],
    },
    {
      id: 2,
      title: "GROUP CLASSES",
      details: [
        "ZUMBA",
        "PILATES",
        "LATIN BOLLY",
        "HIIT",
        "DANCE FIT",
        "YOGA",
        "POWER YOGA",
        "BOLLY-BEATS",
        "TAE BO",
        "TABATA",
        "BOOTCAMP",
        "CROSSFIT",
        "CIRCUIT TRAINING",
        "ABT",
        "DANCE FITNESS",
      ],
    },
    {
      id: 3,
      title: "FACILITY",
      details: [
        "BMI CHECK UP",
        "WORKOUT PLAN",
        "DIET PLAN",
        "SHOWERS",
        "LOCKERS",
        "STEAM",
      ],
    },
  ],
  trainers: [
    {
      id: "01",
      img: "/./images/trainers/arekere/arekere_trainer_1.jpeg",
      name: "Mohan",
      role: "Weight loss & Ectomorph",
      certifications: "Proton",
      experience: "6 Years",
    },
    {
      id: "02",
      img: "/./images/trainers/arekere/arekere_trainer_2.jpeg",
      name: "Charan",
      role: "Strengthening workout, Functional training",
      certifications: "HIIT Certified",
      experience: "6+ Years",
    },
    {
      id: "03",
      img: "/./images/trainers/arekere/arekere_trainer_3.jpeg",
      name: "Vinod",
      role: "Weight training, Cross fit",
      certifications: "NSQF LEVEL 4",
      experience: "5 Years",
    },
  ],
  galleryImgs: [
    {
      imgUrl: "/./images/trainers/arekere/arekere_img1.jpeg",
    },
    {
      imgUrl: "/./images/trainers/arekere/arekere_img2.jpeg",
    },
    {
      imgUrl: "/./images/trainers/arekere/arekere_img3.jpeg",
    },
    {
      imgUrl: "/./images/trainers/arekere/arekere_img4.jpeg",
    },
    {
      imgUrl: "/./images/trainers/arekere/arekere_img5.jpeg",
    },
    {
      imgUrl: "/./images/trainers/arekere/arekere_img6.jpeg",
    },
    {
      imgUrl: "/./images/trainers/arekere/arekere_img7.jpeg",
    },
    {
      imgUrl: "/./images/trainers/arekere/arekere_img8.jpeg",
    },
  ],
};

export const VIJAYA_BANK_LAYOUT = {
  bgImg: "/./images/branchs/vijaya_bank_bg_img.jpeg",
  mapsrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.07753143271148!2d77.61134074005129!3d12.892250934416817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15a3b2427121%3A0x683487804d9b38b0!2sGame%20On%20Fitness%20Premium%20Club%20-%20Vijaya%20Bank%20Layout!5e0!3m2!1sen!2sin!4v1665323938905!5m2!1sen!2sin",
  area: "VIJAYA BANK LAYOUT",
  subtitle:
    "Premium Fitness Centre & Gym in Vijaya bank layout, Providing With Steam, Shower & Locker",
  description:
    "Game On Fitness is dedicated to covering the full fitness landscape, regularly introducing fans to new trends in training, nutrition, gear and technology",
  address:
    "3rd floor, Vijaya Bank Layout circle, near Indian Oil petrol bunk, Bilekahalli, Bengaluru - 560076",
  contactNumber1: "9035279516",
  contactNumber2: "9035279517",
  businessHours: [
    { id: 1, day: "Monday", timings: "5:00 AM – 11:00 PM" },
    { id: 2, day: "Tuesday", timings: "5:00 AM – 11:00 PM" },
    { id: 3, day: "Wednesday", timings: "5:00 AM – 11:00 PM" },
    { id: 4, day: "Thursday", timings: "5:00 AM – 11:00 PM" },
    { id: 5, day: "Friday", timings: "5:00 AM – 11:00 PM" },
    { id: 6, day: "Saturday", timings: "5:00 AM – 11:00 PM" },
    { id: 7, day: "Sunday", timings: "7:00 AM – 09:00 PM" },
  ],
  trainings: [
    {
      id: 1,
      title: "FITNESS GYM",
      details: [
        "WEIGHT LOSS PROGRAM",
        "WEIGHT GAIN PROGRAM",
        "BODY TONING",
        "CARDIO",
        "WEIGHT & STRENGTH",
        "VIPER TRAINIG",
      ],
    },
    {
      id: 2,
      title: "FACILITY",
      details: [
        "BMI CHECK UP",
        "WORKOUT PLAN",
        "DIET PLAN",
        "SHOWERS",
        "LOCKERS",
        "STEAM",
      ],
    },
  ],
  trainers: [
    {
      id: "01",
      img: "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_trainer1.jpeg",
      name: "Bharath",
      role: "Weight loss and weight gain",
      certifications: "Proton",
      experience: "2 Years",
    },
    {
      id: "02",
      img: "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_trainer2.jpeg",
      name: "Kiran",
      role: "Strength and conditioning",
      certifications: "FSSA",
      experience: "2 Years",
    },
    {
      id: "02",
      img: "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_trainer3.jpeg",
      name: "Nandu",
      role: "Strength & Conditioning",
      certifications: "ISSA | CPT",
      experience: "5+ Years",
    },
  ],
  galleryImgs: [
    {
      imgUrl:
        "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_img2.jpeg",
    },
    {
      imgUrl:
        "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_img3.jpeg",
    },
    {
      imgUrl:
        "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_img4.jpeg",
    },
    {
      imgUrl:
        "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_img5.jpeg",
    },
    {
      imgUrl:
        "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_img6.jpeg",
    },
    {
      imgUrl:
        "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_img7.jpeg",
    },
    {
      imgUrl:
        "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_img8.jpeg",
    },
    {
      imgUrl:
        "/./images/trainers/vijaya_bank_layout/vijaya_bank_layout_img9.jpeg",
    },
  ],
};

export const JP_NAGAR = {
  bgImg: "/./images/branchs/jpnagar_bg_img.jpeg",
  mapsrc:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15557.177035430755!2d77.5820823!3d12.8887906!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeaec40b9edb0f4b4!2sGame%20On%20Fitness%20JP%20Nagar!5e0!3m2!1sen!2sin!4v1669714163710!5m2!1sen!2sin",
  area: "JP NAGAR",
  subtitle:
    "Premium Fitness Centre & Gym in JP Nagar, Providing With Steam, Shower & Locker",
  description:
    "IT’S NEVER TOO LATE AND YOU ARE NEVER TOO OLD TO BECOME BETTER!",
  address:
    "Above Poorvika Mobiles, Opp to RBI Layout Bus Stop, JP Nagar 7th phase, Bengaluru - 560078",
  contactNumber1: "9980615580",
  contactNumber2: "9980615580",
  businessHours: [
    { id: 1, day: "Monday", timings: "5:00 AM – 11:00 PM" },
    { id: 2, day: "Tuesday", timings: "5:00 AM – 11:00 PM" },
    { id: 3, day: "Wednesday", timings: "5:00 AM – 11:00 PM" },
    { id: 4, day: "Thursday", timings: "5:00 AM – 11:00 PM" },
    { id: 5, day: "Friday", timings: "5:00 AM – 11:00 PM" },
    { id: 6, day: "Saturday", timings: "5:00 AM – 11:00 PM" },
    { id: 7, day: "Sunday", timings: "7:00 AM – 09:00 PM" },
  ],
  trainings: [
    {
      id: 1,
      title: "FITNESS GYM",
      details: [
        "WEIGHT LOSS PROGRAM",
        "WEIGHT GAIN PROGRAM",
        "BODY TONING",
        "CARDIO",
        "WEIGHT & STRENGTH",
        "VIPER TRAINIG",
        "KETTLE BELL",
        "BATTLE ROPE",
      ],
    },
    {
      id: 2,
      title: "FACILITY",
      details: [
        "BMI CHECK UP",
        "WORKOUT PLAN",
        "DIET PLAN",
        "SHOWERS",
        "LOCKERS",
        "STEAM",
      ],
    },
  ],
  trainers: [
    {
      id: "01",
      img: "/./images/trainers/jpnagar/jpnagar_trainer_1.jpeg",
      name: "Shivaraju R",
      role: "Strengthening",
      certifications: "PROTON",
      experience: "9 Years",
    },
    {
      id: "02",
      img: "/./images/trainers/jpnagar/jpnagar_trainer_2.jpeg",
      name: "Chethan Kumar",
      role: "S&C, Body Building",
      certifications: "ACE PREP | CPR | CPT",
      experience: "7 Years",
    },
    {
      id: "03",
      img: "/./images/trainers/jpnagar/jpnagar_trainer_3.jpeg",
      name: "Madhan. R",
      role: "Weight loss",
      certifications: "CPR | Proton",
      experience: "2 Years",
    },
  ],
  galleryImgs: [
    {
      imgUrl: "/./images/trainers/jpnagar/jpnagar_img1.jpeg",
    },
    {
      imgUrl: "/./images/trainers/jpnagar/jpnagar_img2.jpeg",
    },
    {
      imgUrl: "/./images/trainers/jpnagar/jpnagar_img3.jpeg",
    },
    {
      imgUrl: "/./images/trainers/jpnagar/jpnagar_img4.jpeg",
    },
    {
      imgUrl: "/./images/trainers/jpnagar/jpnagar_img5.jpeg",
    },
    {
      imgUrl: "/./images/trainers/jpnagar/jpnagar_img6.jpeg",
    },
  ],
};

export const WILSON_GARDEN = {
  bgImg: "/./images/branchs/wilson_garden_bg_img.jpeg",
  mapsrc:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.3235477171!2d77.5960215!3d12.9506662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5b2c28cdbb658004!2sGame%20On%20Fitness%20Wilson%20Garden!5e0!3m2!1sen!2sin!4v1669714606634!5m2!1sen!2sin",
  area: "WILSON GARDEN",
  subtitle:
    "Premium Fitness Centre & Gym in Wilson Garden, Providing With Steam, Shower & Locker",
  description: "LET THE GAINS BEGIN!",
  address:
    "Opp to Traffic Police Station, Vinayaka Nagar, Wilson Garden, Bengaluru - 560027",
  contactNumber1: "9663995409",
  contactNumber2: "9663995409",
  businessHours: [
    { id: 1, day: "Monday", timings: "5:00 AM – 11:00 PM" },
    { id: 2, day: "Tuesday", timings: "5:00 AM – 11:00 PM" },
    { id: 3, day: "Wednesday", timings: "5:00 AM – 11:00 PM" },
    { id: 4, day: "Thursday", timings: "5:00 AM – 11:00 PM" },
    { id: 5, day: "Friday", timings: "5:00 AM – 11:00 PM" },
    { id: 6, day: "Saturday", timings: "5:00 AM – 11:00 PM" },
    { id: 7, day: "Sunday", timings: "7:00 AM – 09:00 PM" },
  ],
  trainings: [
    {
      id: 1,
      title: "FITNESS GYM",
      details: [
        "WEIGHT LOSS PROGRAM",
        "WEIGHT GAIN PROGRAM",
        "BODY TONING",
        "CARDIO",
        "WEIGHT & STRENGTH TRAINING",
        "VIPER TRAINIG",
        "KETTLE BELL",
        "BATTLE ROPE",
      ],
    },
    {
      id: 2,
      title: "FACILITY",
      details: [
        "BMI CHECK UP",
        "WORKOUT PLAN",
        "DIET PLAN",
        "SHOWERS",
        "LOCKERS",
        "STEAM",
      ],
    },
  ],
  trainers: [
    {
      id: "01",
      img: "/./images/trainers/wilson_garden/wilson_garden_trainer_1.jpeg",
      name: "Prabhu",
      role: "S & C Muscle gain",
      certifications: "ACE | PROTON",
      experience: "15 Years",
    },
    {
      id: "02",
      img: "/./images/trainers/wilson_garden/wilson_garden_trainer_2.jpeg",
      name: "Madhu",
      role: "Weight loss",
      certifications: "NSQF",
      experience: "4 Years",
    },
    {
      id: "03",
      img: "/./images/trainers/wilson_garden/wilson_garden_trainer_3.jpeg",
      name: "Panchami",
      role: "Functional training",
      certifications: "FSSA",
      experience: "2 Years",
    },
    {
      id: "04",
      img: "/./images/trainers/wilson_garden/wilson_garden_trainer_4.jpeg",
      name: "Prashanth",
      role: "Weight Gain | HIIT",
      certifications: "Proton",
      experience: "5 Years",
    },
  ],
  galleryImgs: [
    {
      imgUrl: "/./images/trainers/wilson_garden/wilson_garden_img1.jpeg",
    },
    {
      imgUrl: "/./images/trainers/wilson_garden/wilson_garden_img2.jpeg",
    },
    {
      imgUrl: "/./images/trainers/wilson_garden/wilson_garden_img3.jpeg",
    },
    {
      imgUrl: "/./images/trainers/wilson_garden/wilson_garden_img4.jpeg",
    },
    {
      imgUrl: "/./images/trainers/wilson_garden/wilson_garden_img5.jpeg",
    },
    {
      imgUrl: "/./images/trainers/wilson_garden/wilson_garden_img6.jpeg",
    },
    {
      imgUrl: "/./images/trainers/wilson_garden/wilson_garden_img7.jpeg",
    },
    {
      imgUrl: "/./images/trainers/wilson_garden/wilson_garden_img8.jpeg",
    },
    {
      imgUrl: "/./images/trainers/wilson_garden/wilson_garden_img9.jpeg",
    },
  ],
};

export const AKSHAYANAGAR = {
  mapsrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.0929260499972!2d77.61526840588091!3d12.876387910391326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6b9b280b2103%3A0xd1043065d95d2bd8!2sGame%20On%20Fitness%20Luxury%20Club%20-%20Akshayanagar!5e0!3m2!1sen!2sin!4v1669715232226!5m2!1sen!2sin",
  area: "AKSHAYANAGAR",
  subtitle: "Largest and Luxurious Fitness Centre & Gym in Akshayanagar",
  description: "EMPOWER YOURSELF TO MAKE THE CHANGE YOU NEED TO MAKE",
  address: "Above Reliance Smart, near DLF, Akshayanagar, Bengaluru - 560068",
  contactNumber1: "8431198114",
  contactNumber2: "9148931009",
  contactNumber3: "8431198114",
  businessHours: [
    { id: 1, day: "Monday", timings: "5:00 AM – 11:00 PM" },
    { id: 2, day: "Tuesday", timings: "5:00 AM – 11:00 PM" },
    { id: 3, day: "Wednesday", timings: "5:00 AM – 11:00 PM" },
    { id: 4, day: "Thursday", timings: "5:00 AM – 11:00 PM" },
    { id: 5, day: "Friday", timings: "5:00 AM – 11:00 PM" },
    { id: 6, day: "Saturday", timings: "5:00 AM – 11:00 PM" },
    { id: 7, day: "Sunday", timings: "7:00 AM – 09:00 PM" },
  ],

  trainings: [
    {
      id: 1,
      title: "FITNESS GYM",
      details: [
        "WEIGHT LOSS PROGRAM",
        "WEIGHT GAIN PROGRAM",
        "BODY TONING",
        "CARDIO",
        "WEIGHT & STRENGTH TRAINING",
        "VIPER TRAINIG",
        "KETTLE BELL",
        "BATTLE ROPE",
      ],
    },
    {
      id: 2,
      title: "GROUP CLASSES",
      details: [
        "ZUMBA",
        "PILATES",
        "LATIN BOLLY",
        "HIIT",
        "DANCE FIT",
        "YOGA",
        "POWER YOGA",
        "BOLLY-BEATS",
        "TAE BO",
        "TABATA",
        "BOOTCAMP",
        "CROSSFIT",
        "CIRCUIT TRAINING",
        "ABT",
        "DANCE FITNESS",
      ],
    },
    {
      id: 3,
      title: "FACILITY",
      details: [
        "BMI CHECK UP",
        "WORKOUT PLAN",
        "DIET PLAN",
        "SHOWERS",
        "LOCKERS",
        "STEAM",
      ],
    },
  ],

  trainers: [
    {
      id: "01",
      img: "/./images/trainers/akshayanagar/akshayanagar_trainer_1.jpeg",
      name: "Anil",
      role: "Fat loss | S&C",
      certifications: "Reps India | NSQC Level 4 (CPR)",
      experience: "5 Years",
    },
    {
      id: "02",
      img: "/./images/trainers/akshayanagar/akshayanagar_trainer_2.jpeg",
      name: "Prajwal",
      role: "Strength & Conditioning",
      certifications: "Australian S&C Level 1",
      experience: "4+ Years",
    },
    {
      id: "02",
      img: "/./images/trainers/akshayanagar/akshayanagar_trainer_2.jpeg",
      name: "Raju",
      role: "Transformation | S&C",
      certifications: "K11 fitness science | NSQC Level 4",
      experience: "7 Years",
    },
  ],

  galleryImgs: [
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img1.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img2.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img3.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img4.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img5.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img6.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img7.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img8.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img9.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img10.jpeg",
    },
    {
      imgUrl: "/./images/trainers/akshayanagar/akshayanagar_img11.jpeg",
    },
  ],
};

// Token

export const TOKEN =
  "IGQVJWRzc5UVBFSU13VDNpVDYyNGRkeG1wYWhmcmNUTHNSemlRcFpoVkQ2SVF2Y0tOOVQyTldOSlJMZA0lXZAWxtcXNKM2IwRlNfUTFMalVpZAGpiWFRpeHRqUWMwZAjNSZAXpQdC1URVJYcUtSNEExcDVQdwZDZD";

export const FAQ_DATA = [
  {
    id: "01",
    question: "1. How often should I workout to see results?",
    answer:
      "The frequency of workouts required to see results can vary based on several factors, including age, fitness level, and goals. However, as a general guideline, it is recommended that adults engage in at least 150 minutes of moderate-intensity exercise or 75 minutes of vigorous-intensity exercise per week. This can be spread out over several sessions, such as 30 minutes of exercise on five days of the week. If your goal is to build muscle, you may need to increase the frequency and intensity of your workouts, focusing on resistance training with weights or bodyweight exercises. It's always best to consult with a certified personal trainer or a physician before starting a workout routine.",
  },
  {
    id: "02",
    question: "2. What are the best exercises to target specific muscle groups?",
    answer: "1. Chest: Push-ups, bench press, dumbbell flyes \n\n2. Back: Pull-ups, rows, deadlifts \n\n3. Shoulders: Military press, lateral raises, front raises \n\n4. Biceps: Bicep curls, hammer curls, chin-ups \n\n5. Triceps: Tricep dips, skull crushers, tricep extensions \n\n6. Legs: Squats, lunges, deadlifts, leg press \n\n7. Glutes: Squats, lunges, deadlifts, hip thrusts \n\n8. Abs: Planks, crunches, Russian twists, bicycle crunches \n\nIt is important to remember that targeting specific muscle groups should be done in conjunction with a well-rounded exercise routine that includes cardio and full-body strength training.",
  },
  {
    id: "03",
    question: "3. How can I improve my flexibility and range of motion?",
    answer: "1. Stretching: Incorporate stretching exercises into your daily routine. This helps increase muscle elasticity and flexibility, which can improve your range of motion. \n\n2. Yoga or Pilates: Practicing yoga or Pilates regularly can help increase flexibility, balance, and range of motion. \n\n3. Foam rolling: Using a foam roller can help relax tight muscles, which can improve your flexibility. \n\n4. Dynamic warm-up: Before exercising or engaging in any physical activity, perform a dynamic warm-up to get your body moving and increase your range of motion. \n\n5. Regular exercise: Regular exercise can help improve your overall fitness levels, including flexibility and range of motion. \n\n6. Proper nutrition: Eating a balanced diet and staying hydrated can help keep your muscles healthy and flexible. \n\n7. Rest and recovery: Make sure to give your body adequate time to rest and recover between workouts to reduce the risk of injury and promote flexibility.",
  },
  {
    id: "04",
    question: "4. How important is nutrition when it comes to achieving fitness goals?",
    answer: "Nutrition is essential when it comes to achieving fitness goals. A proper diet helps improve energy levels and enables an individual to perform better during workouts. Additionally, proper nutrition provides the body with the necessary nutrients needed for recovery post-workout that aids in building more muscle mass, losing fat, and improving one's overall fitness level. A lack of proper nutrition can lead to fatigue, decreased performance, and slow progress. Therefore, a well-balanced and healthy diet is important to achieve fitness goals.",
  },
  {
    id: "05",
    question: "5. Is it necessary to use supplements?",
    answer: "It is not always necessary to use supplements as long as they maintain a balanced diet and live a healthy lifestyle. In some cases, supplements can be recommended by healthcare professionals to address nutrient deficiencies or medical conditions. It is important to consult with a healthcare provider before starting any supplement regimen.",
  },
  {
    id: "06",
    question: "6. What are the benefits of strength training?",
    answer: "1. Increased muscle mass: Strength training helps to build muscle mass, which can improve your overall appearance and increase your metabolism. \n\n2. Improved bone density: Strength training can help to increase bone density, which can reduce the risk of osteoporosis, especially in older adults. \n\n3. Better body composition: Strength training can help you decrease body fat and increase lean muscle mass, which can improve your overall health and fitness levels. \n\n4. Increased strength and power: Regular strength training can help improve your strength and power, which can enhance your ability to perform daily activities and sports-specific movements. \n\n5. Reduced risk of injury: Strength training strengthens your muscles, tendons, and ligaments, which can help reduce the risk of injuries during physical activities. \n\n6. Improved balance and stability: Strength training can help to improve your balance and stability, which can reduce the risk of falls, especially in older adults. \n\n7. Greater overall health: Strength training has been shown to have many positive effects on overall health, including improved cardiovascular health, blood pressure, and cholesterol levels. \n\n8. Enhanced mental health: Strength training has been shown to be beneficial for mental health, reducing symptoms of anxiety and depression and improving overall mood.",
  },
  {
    id: "07",
    question: "7. Is it better to do cardio before or after strength training?",
    answer: "Studies suggest that it's better to do strength training before cardio. This is because strength training requires more focus, energy, and effort, and doing it first ensures you have the energy to perform the exercises properly without risking injury. Additionally, strength training can also help improve your cardiovascular health and burn calories, so doing cardio afterward may not be necessary. However, ultimately it depends on your fitness goals and personal preferences. It's important to find a routine that works best for you and your body.",
  },
  {
    id: "08",
    question: "8. Can I build muscle without lifting heavy weights?",
    answer: "Yes, it is possible to build muscle without lifting heavy weights. Resistance training can be done with various tools such as resistance bands, bodyweight exercises, or even household items like water bottles or cans. It is also important to focus on proper nutrition and adequate rest to support muscle growth.",
  },
];
