/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

function YTVideoEmbed({url}) {
  return (
    <div css={styles}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/4kevhozw_UU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default YTVideoEmbed;


const styles = css`
  width: 100%;
  display: flex;
  align-items:center;
  justify-content: center;
  margin: 45px 0 45px 0px;

  @media (max-width: 500px) {
    margin: 45px 0 45px 0;
  }
`;

