/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import StartTrialButton from "../Main/StartTrialButton";

const CallToAction = ({ setShowForm, onBtnClick, showEnquiryBtn }) => {
  return (
    <div css={styles} className="gymProgram" id="branches">
      <img src="/./images/gof-journey.jpg" alt="Services" />

      <div className="btn-container">
        <StartTrialButton
          hideBanner
          hideCallBtn
          title="BOOK FREE CONSULTATION"
          openForm={() => {
            setShowForm(true);
          }}
        />

        {showEnquiryBtn && (
          <a href={`tel: 91489 74009`}>
            {" "}
            <i className="fas fa-phone"></i> Call Now
          </a>
        )}
      </div>

    </div>
  );
};

const styles = css`
  width: 100%;
  // min-height: 100vh;
  text-align: center;
  // padding: 0 0 30px 0;
  margin: 25px 0 25px 0;

  h2 {
    color: #232d39;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 30px;
    span {
      color: #fe640e;
    }
  }

  p {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 0;
  }

  img {
    width: 100%;
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  a {
    color: #ffffff;
    // background: #f65b37;
    /* background: rgba(254, 100, 14, 1); */
    background: linear-gradient(
      90deg,
      rgba(254, 147, 86, 1) 9%,
      rgba(254, 100, 14, 1) 100%
    );
    text-decoration: none;
    padding: 13px 40px;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  .fa-phone {
    rotate: 95deg;
  }

  @media (max-width: 700px) {
    .container {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  @media (min-width: 701px) and (max-width: 1100px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 450px) {
    padding: 10px 0 0 0;

    h2 {
      font-size: 23px;
      padding: 0 18px;
    }

    p {
      font-size: 14px;
    }

    a {
      font-size: 14px;
      padding: 10px 10px;
    }
  }
`;

export default CallToAction;
