/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const Table = ({ timing }) => {
  return (
    <table css={styles}>
      <tbody>
        {timing?.map((item, index) => {
          return (
            <tr key={"timing_" + index}>
              <td>{item?.day}</td>
              <td>
                <span>{item?.timings}</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const styles = css`
  border-collapse: collapse;
  color: #fff;
  margin: 30px 0 0 0;
  td,
  th {
    border: 1px solid #fff;
    border-collapse: collapse;
  }
  tr {
    td {
      padding: 40px 0;
      width: 200px;
      span {
        opacity: 1;
        transition: opacity 900ms ease-in-out;
      }
    }
  }
  .hidden {
    opacity: 0;
  }
  @media (max-width: 640px) {
    font-size: 12px;
    tr {
      td {
        padding: 40px 0;
        width: 200px;
        span {
          font-size: 11px;
        }
      }
    }
  }
`;

export default Table;
