/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Socials from "./Socials";

const TrainerCard = ({ title, details, name, desc, img }) => (
  <div css={styles} className="card">
    {/* <img src={img} alt="trainer" /> */}
    <h3>{title}</h3>
    <ul>
      {details?.map((item, index) => {
        return (
          <li key={"detail_" + index}>
            <p>{item}</p>
          </li>
        );
      })}
    </ul>
    {/* <h3>{name}</h3> */}
    {/* <p>{desc}</p> */}
    {/* <Socials /> */}
  </div>
);

const styles = css`
  width: 100%;
  max-width: 31%;
  background: #fff;
  padding: 38px 44px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  text-align: left;
  border-radius: 4px;
  
  img {
    width: 100%;
  }
  h3 {
    color: #fe640e;
    margin: 0px 0px 20px 0;
    font-weight: 500;
    font-size: 16px;
  }
  // h3 {
  //   color: #232d39;
  //   letter-spacing: 1px;
  // }
  p {
    margin: 12px 0 0 0;
    color: #7a7a7a;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
  }
  ul {
    margin: 0px 0 0px 15px;
  }
  @media (max-width: 830px) {
    max-width: 540px;
    margin: 14px 0;
  }
  @media (min-width: 831px) and (max-width: 1226px) {
    max-width: 47%;
    margin: 14px 0;
  }
`;

export default TrainerCard;
