/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const GymTitle = () => (
  <h2 css={styles}>
    CLUB <span>LOCATIONS</span>
  </h2>
);

const styles = css`
  color: #232d39;
  font-size: 26px;
  font-weight: 900;
  line-height: 1;
  span {
    color: #fe640e;
  }

  @media (max-width: 450px) {
    font-size: 23px;
  }
`;

export default GymTitle;
