/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  let navigate = useNavigate();

  return (
    <img
      css={styles}
      onClick={() => navigate("/")}
      src="/./images/gof-logo.png"
      alt="Game on Fitness"
    />

    // <h2 css={styles} onClick={() => navigate("/")}>
    //   {/* GAME ON <span>FITNESS</span> */}
    //   {/* TRAINING <span>STUDIO</span> */}
    // </h2>
  );
};

const styles = css`
  width: 380px;
  cursor: pointer;
  @media (max-width: 450px) {
    width: 220px;
  }
`;

// const styles = css`
//   color: #fff;
//   font-size: 30px;
//   font-weight: 900;
//   line-height: 1;
//   cursor: pointer;
//   span {
//     color: #ed563b;
//   }

//   @media (max-width: 400px) {
//     font-size: 20px;
//   }
// `;

export default Logo;
