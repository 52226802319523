/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

function FooterAbout() {
  return (
    <div css={styles}>
      <img src="/./images/gof-footer-logo.png" alt="Game on Fitness" />
      <p>Bangalore, Karnataka</p>
      <p>info@gameonfitness.in</p>

      <div className="social-icons">
        <a
          href="https://m.facebook.com/112450367880632/"
          target="_blank"
          className="icon-fb"
        >
          <i class="fab fa-facebook-square"></i>
        </a>

        <a
          href="https://www.instagram.com/gameonfitness_/"
          target="_blank"
          className="icon-insta"
        >
          <i class="fab fa-instagram"></i>
        </a>

        <a
          href="https://www.youtube.com/channel/UCBNqbUliZ_vyJhpt-egzUFw"
          target="_blank"
          className="icon-youtube"
        >
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>
  );
}

export default FooterAbout;

const styles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 45px 0 15px 0px;

  img {
    width: 250px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 18px;
    color: #242424;
    line-height: 32px;
    margin-top: 15px;
  }

  p {
    font-size: 14px;
    color: #242424;
    line-height: 27px;
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 15px;

    a {
      font-size: 22px;
      text-decoration: none;
    }
  }

  .icon-fb {
    color: #1773ea;
  }
  .icon-insta {
    color: #cb2d8b;
  }
  .icon-youtube {
    color: #f70000;
  }

  @media (max-width: 500px) {
    margin: 45px 0 15px 0;
  }
`;
