/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import GymTitle from "./GymTitle";
import { useNavigate } from "react-router-dom";
import { BRANCH_DETAILS } from "../../utils/data";
import LocationCard from "./LocationCard";
import Slider from "react-slick";

const GymProgram = () => {
  let navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div css={styles} className="gymProgram" id="branches">
      <GymTitle />

      <img src="/./images/gof-locations.jpeg" alt="Game on fitness Offer" />

      <div className="content">
        <Slider {...settings}>
          {BRANCH_DETAILS.map((item, index) => {
            return (
              <LocationCard
                key={item.title + index}
                title={item.title}
                desc={item.desc}
                onClick={() => navigateTo(item.link)}
                color={item.color}
                bgImg={item?.bgImg}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

const styles = css`
  text-align: center;
  /* padding: 10px 0 50px; */
  border-radius: 10px;
  padding: 25px 15px 20px 0;
  margin: 0 25px 10px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  .content {
    margin: 30px 0 20px 0;
    border-radius: 10px;
    background-color: #ffffff;
  }

  img {
    width: 100%;
    margin: 20px 0 0 0;
  }

  @media (max-width: 500px) {
    padding: 20px 0 20px 0;
    margin: 0 20px 10px 20px;

    .content {
      padding: 0 0 40px 0;
      margin: 10px 0px 0 0px;
    }
  }
`;

export default GymProgram;
