/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const StartTrialButton = ({ title, openForm, hideBanner, contactNumber1, showEnquiryBtn }) => (
  <section css={styles} style={{ marginTop: hideBanner ? "50px" : "0px" }}>
    {!hideBanner ? (
      <img src="/./images/gof_banner.jpg" alt="Game on fitness Offer" />
    ) : null}

    <div className="btn-container">
      <button onClick={openForm}>{title || "Book Your Free Trial Now"}</button>
      {showEnquiryBtn && (
        <a href={`tel: 91489 74009`}>
          {" "}
          <i className="fas fa-phone"></i> Call Now
        </a>
      )}

      {hideBanner ? (
        contactNumber1 ? (
          <a href={`tel: ${contactNumber1}`}>
            {" "}
            <i className="fas fa-phone"></i> Call Now
          </a>
        ) : null
      ) : null}
    </div>
  </section>
);

const styles = css`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 50px 0;

  img {
    width: 100%;
    margin-bottom: 40px;
  }

  button {
    font-size: 18px;
    font-weight: 500;
    // border: 2px solid #ed563b;
    border: none;
    border-radius: 3px;
    // background-color: #ed563b;
    background: linear-gradient(
      90deg,
      rgba(254, 147, 86, 1) 9%,
      rgba(254, 100, 14, 1) 100%
    );
    color: #ffffff;
    padding: 12px 40px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  button:hover {
    color: #fe640e;
    background: #f8f9fa;
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .fa-phone {
    rotate: 95deg;
  }

  a {
    color: #ffffff;
    // background: #f65b37;
    /* background: rgba(254, 100, 14, 1); */
    background: linear-gradient(
      90deg,
      rgba(254, 147, 86, 1) 9%,
      rgba(254, 100, 14, 1) 100%
    );
    text-decoration: none;
    padding: 13px 40px;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 500px) {
    button {
      font-size: 14px;
      padding: 11px 10px;
    }

    a {
      font-size: 14px;
      padding: 10px 10px;
    }
  }
`;

export default StartTrialButton;
