/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { FAQ_DATA } from "../../utils/data";
import FAQItem from "./FAQItem";

function FAQSection() {
  return (
    <div css={styles} id="faq">
      <h2>
        FREQUENTLY ASKED <span>QUESTIONS</span>
      </h2>

      <div className="content">
        <div>
          {FAQ_DATA?.map((item, index) => (
            <FAQItem
              key={`faq_${index}`}
              question={item?.question}
              answer={item?.answer}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQSection;

const styles = css`
  /* margin: 0 0 85px 0px; */

  border-radius: 10px;
  padding: 25px 0 45px 0;
  margin: 35px 25px 10px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  h2 {
    color: #232d39;
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    margin-bottom: 25px;
    text-align: center;
  }

  span {
    color: #fe640e;
  }

  .content {
    margin: 20px 20px 0 20px;
    background-color: #FAFAFA;
    border-radius: 8px;
  }

  @media (max-width: 500px) {
    padding-bottom: 40px;
    margin: 35px 25px 40px 25px;

    h2 {
      font-size: 20px;
      padding: 0 18px;
      margin-bottom: 0;
    }
  }
`;
