import React from "react";
import Footer from "../components/Footer/Footer";
import Container from "../components/GlobalComponents/Container";
import Main from "../components/Main/Main";
import UiImage from "../components/Image/classOne.jpg";
import Nav from "../components/Navbar/Nav";
import FooterNew from "../components/Footer/FooterNew";

function AboutUs({ data }) {
  return (
    <div className="App">
      {/* <Main
        area={data?.area}
        subtitle={data?.subtitle}
        description={data?.description}
        activePage="about"
        hideInfo
        onlyNavbar
      /> */}
      <Nav activePage="about" onlyNavbar />

      <Container>
        <div className="about-container">
          <div className="about-content">
            <h2>
              About <span style={{ color: "#fe640e" }}>Game On Fitness</span>{" "}
            </h2>

            <h3>Who we are</h3>

            <p>
              Game On Fitness is a health and fitness company offering
              experiences across fitness, nutrition, and mental well-being.
            </p>

            <p>
              We are dedicated to covering the full fitness landscape, regularly
              introducing fans to new trends in training, nutrition, gear and
              technology. Game On Fitness is the essential training partner.
            </p>

            <h4>We are different than the rest</h4>

            <ul>
              <li>
                We have filled the gym with top of the line machines, weights
                and workout accessories to help all of our members reach their
                personal goals.
              </li>
              <li>
                Each Game On Fitness club offers high quality cardio & strength
                equipment.
              </li>
              <li>
                High quality Interiors to offer exceptional ambience for
                workout.
              </li>
              <li>
                Ace certified Advanced Personal Trainers to offer great workout
                routine.
              </li>
            </ul>
          </div>

          <div className="about-img-container">
            <div></div>
            <img
              className="about-img"
              src={UiImage}
              alt="About Game on Fitness"
            />
          </div>
        </div>
      </Container>
      {/* <Footer /> */}
      <div style={{marginTop:'50px'}}>
        <FooterNew />
      </div>
    </div>
  );
}

export default AboutUs;
