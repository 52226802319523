import React from "react";
import Main from "../components/Main/Main";
import GymProgram from "../components/GymProgram/GymProgram";
import Footer from "../components/Footer/Footer";
import Testimonial from "../components/Testimonial/Testimonial";
import StartTrialButton from "../components/Main/StartTrialButton";
import ClientTransformations from "../components/ClientTransformations/ClientTransformations";
import Servies from "../components/Services/Services";
import ScrollToTopButton from "../components/ScrollToTopButton";
import ModalCard from "../components/ModalCard";
import { useState } from "react";
import CallToAction from "../components/CallToAction/CallToAction";
import FooterAbout from "../components/FooterAbout/FooterAbout";
import InstaFeeds from "../components/InstaFeeds/InstaFeeds"
import FooterNew from "../components/Footer/FooterNew";
import FAQSection from "../components/FAQ/FAQSection";
import WhatsappFAB from "../components/WhatsappFAB";

function Home() {
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="App">
      <Main activePage="home" showBGVideo hideInfo showHeroSection/>
      <StartTrialButton
        openForm={() => {
          setShowForm(true);
        }}
        showEnquiryBtn
      />
      <GymProgram />
      <Servies />
      <ClientTransformations />
      <Testimonial />
      <InstaFeeds />
      <CallToAction
        setShowForm={setShowForm}
        onBtnClick={() => {
          setShowForm(true);
        }}
        showEnquiryBtn
      />
      <FAQSection />

      {/* <FooterAbout /> */}
      {/* <Member
        onBtnClick={() => {
          setShowForm(true);
        }}
      /> */}
      {/* <Classes /> */}
      {/* <Schedule /> */}
      {/* <Trainers /> */}
      {/* <Contact /> */}
      {/* <Footer /> */}
      <FooterNew />
      <ScrollToTopButton />

      <ModalCard
        open={showForm}
        handleClose={() => {
          setShowForm(false);
        }}
      >
        <iframe
          width="100%"
          height="600"
          src="https://www.yoactiv.com/widenq.aspx?adminid=y2FvcFtxxlokAMCOLflRKA==&leadsrc=9"
          frameborder="0"
        ></iframe>
      </ModalCard>

      <WhatsappFAB />
    </div>
  );
}

export default Home;
