/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

function Footer() {
  return (
    <section css={styles}>
      <footer>
        <p>
          Copyright © 2022{" "}
          <a href="#" target="_blank">
            GAME ON FITNESS
          </a>
        </p>
      </footer>
    </section>
  );
}

const styles = css`
  width: 100%;
  text-align: center;
  padding 0px 0 0 0;
  h2 {
    color: #232d39;
    font-weight: 900;
    font-size: 36px;
    letter-spacing: 1.3px;
    line-height: 1;
    span {
      color: #ed563b;
    }
  }
  .address {
    text-align:center;
    color: #7a7a7a;
    font-size: 16px;
    line-height: 2;
    margin: 0 40px 30px 40px;
  }
  .container {
    max-width: 100%;
    display: flex;
  }
  footer {
    text-align: center;
    padding: 36px 0;
    p {
      font-size: 15px;
      color: #232d39;
      a {
        text-decoration: none;
        color: #fe640e;
        font-weight: 500;
        &:hover {
          color: #fe9356;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .container {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
`;

export default Footer;
