/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
// import VideoBg from "../Image/bg-video.mp4";
import VideoBg from "../Image/bg-video.m4v";

const Video = () => (
  <div css={styles}>
    <video autoPlay muted loop>
      <source src={VideoBg} type="video/mp4" />
    </video>
  </div>
);

const styles = css`
  /* min-width: 100%;
  min-height: 90vh;
  max-width: 100%;
  max-height: 90vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin: 82px 0 0 0 ;


  video {
    width: auto;
    height: 90vh;
    object-fit: cover;
    aspect-ratio: 1/1;
  }

  @media (max-width: 500px) {
    margin: 64px 0 0 0;
    video {
      width: 100%;
      height: auto;
    }
  }
`;

export default Video;
