/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

function ImageCard({ imgUrl }) {
  return (
    <div css={styles}>
      <img src={imgUrl} alt="Gallery-img" />
    </div>
  );
}

export default ImageCard;

const styles = css`
  overflow: hidden;

  img {
    width: 95%;
    /* height: 280px; */
    object-fit: cover;
    transition: all 1.5s ease;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    margin: 20px;
  }

  @media (max-width: 1060px) {
    img {
      width: calc(100vw - 1rem);
      margin: 20px 10px;
    }
  }

  @media (max-width: 500px) {
    img {
      width: 100%;
      margin: 0;
    }
  }
`;
