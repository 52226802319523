/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import Slider from "react-slick";

import ImageCard from "./ImageCard";

function ImageGallery({ galleryImgs }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div css={styles} id="gallery">
      <h2 >
        <span>GALLERY</span>
      </h2>
      {/* <Icon /> */}

      <div className="container">
        <Slider {...settings}>
          {galleryImgs?.map((item, index) => {
            return <ImageCard imgUrl={item?.imgUrl} />;
          })}
        </Slider>
      </div>
    </div>
  );
}

export default ImageGallery;

const styles = css`
  text-align: center;
  /* margin: 50px 0 85px 0px; */
  
  border-radius: 10px;
  padding: 25px 0 45px 0;
  margin: 35px 25px 10px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  h2 {
    color: #232d39;
    font-size: 26px;
    font-weight: 900;
    line-height: 34px;
    margin: 0;
  }

  span {
    color: #fe640e;
  }

  .container {
    margin: 15px 0 0 0px;
  }

  .gallery-container {
    width: 1070px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 2px;
    column-gap: 5px;
  }

  .transfrom-img {
    width: 100%;
    height: 330px;
    object-fit: cover;
    margin-right: 10px;
  }

  @media (max-width: 1060px) {
    

    .gallery-container {
      width: 100%;
      flex-direction: column;
    }

    .container {
      margin: 0px 0 0 0px;
    }
  }

  @media (max-width: 450px) {
    h2 {
      font-size: 23px;
      padding: 0 18px;
      margin-bottom: 15px;
    }
  }
`;
