/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useState } from "react";
import scheduleBg from "../Image/scheduleBg.jpg";
import Table from "./Table";
import Container from "../GlobalComponents/Container";

const Schedule = ({timing}) => {
  const [hidden, setHidden] = useState(false);
  const [day, setDay] = useState("Monday");

  return (
    <section css={styles} className="schedule" id="schedule">
      <h2>
        BUSINESS <span>HOURS</span>
      </h2>
      <Container>
        {/* <ScheduleLinks setDay={setDay} day={day} /> */}
        <Table day={day} timing={timing} />
      </Container>
    </section>
  );
};

const styles = css`
  width: 100%;
  padding: 50px 0;
  // min-height: 100vh;
  text-align: center;
  background: url("${scheduleBg}") no-repeat center/cover;
  margin-bottom: 45px;

  h2 {
    color: #fff;
    font-weight: 900;
    font-size: 26px;
    letter-spacing: 1.3px;
    line-height: 34px;
    span {
      color: #fe640e;
    }
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 1.7;
    margin: 20px 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 640px) {
    p {
      padding: 0 30px;
      br {
        display: none;
      }
    }
    .container {
      max-width: 92%;
    }
  }
  
  @media (max-width: 450px) {
    h2 {
      font-size: 23px;
      padding: 0 18px;
    }
  }
`;

export default Schedule;
