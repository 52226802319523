/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { TOKEN } from "../../utils/data";

function InstaFeeds() {
  const [feedList, setFeedList] = useState([]);
  const URL = `https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${TOKEN}&limit=8`;

  useEffect(() => {
    // window.getInstaFeeds();

    if (feedList?.length === 0) {
      fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Do something with the data
          console.log("FEED_DATA", data);
          setFeedList(data?.data);
        });
    }

    return () => {};
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return feedList.length > 0 ? (
    <div css={styles}>
      <h2>
        INSTAGRAM <span>UPDATES</span>
      </h2>
      {/* <Icon /> */}

      {/* <div id="instafeed"></div> */}

      <div className="content">
        <Slider {...settings}>
          {feedList.map((item, index) => {
            return (
              <div
                key={"insta_feed" + index}
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/gameonfitness_/",
                    "_blank"
                  );
                }}
              >
                <img
                  className="transfrom-img"
                  src={
                    item?.thumbnail_url ? item?.thumbnail_url : item?.media_url
                  }
                  alt=""
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default InstaFeeds;

const styles = css`
  /* width: 100%; */
  text-align: center;
  /* margin: 0 0 85px 0px; */

  border-radius: 10px;
  padding: 25px 0 45px 0;
  margin: 35px 25px 10px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  h2 {
    color: #232d39;
    font-size: 26px;
    font-weight: 900;
    line-height: 32px;
    margin-bottom: 25px;
  }

  span {
    color: #fe640e;
  }

  .content {
    margin: 20px 0 0 0;
  }

  .transfrom-img {
    width: 90%;
    height: 400px;
    object-fit: contain;
    margin: 0 18px;
  }

  @media (max-width: 500px) {
    padding-bottom: 40px;
    margin: 35px 25px 40px 25px;

    h2 {
      font-size: 23px;
      padding: 0 18px;
      margin-bottom: 0;
    }

    .transfrom-img {
      height: 320px;
    }
  }
`;
