/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Icon from "../GlobalComponents/Icon";
import TrainerCard from "./TrainerCard";
import Container from "../GlobalComponents/Container";
import TrainerOneBg from "../Image/trainerOne.jpg";
import TrainerTwoBg from "../Image/trainerTwo.jpg";
import TrainerThreeBg from "../Image/trainerThree.jpg";

const Trainers = ({ trainingDetails, description }) => (
  <section css={styles} className="trainers" id="trainers">
    <h2>
      OUR <span>TRAINING</span>
    </h2>
    {/* <Icon /> */}
    <p>{description}</p>
    <Container>
      {trainingDetails?.map((item, index) => {
        return (
          <TrainerCard
            key={"training_" + index}
            title={item?.title}
            details={item?.details}
            // name=""
            // desc=""
            // img={TrainerOneBg}
          />
        );
      })}
    </Container>
  </section>
);

const styles = css`
  /* width: 100%; */
  padding: 0 0 50px;
  text-align: center;
  
  border-radius: 10px;
  padding: 25px 0 35px 0;
  margin: 35px 25px 30px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  h2 {
    color: #232d39;
    font-weight: 900;
    font-size: 26px;
    letter-spacing: 1.3px;
    line-height: 32px;
    margin-bottom: 20px;
    span {
      color: #fe640e;
    }
  }

  > p {
    color: #7a7a7a;
    font-size: 16px;
    line-height: 1.7;
  }

  .container {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    padding: 50px 0 0 0;
  }
  @media (max-width: 650px) {
    > p {
      padding: 0 30px;
      br {
        display: none;
      }
    }
  }
  @media (max-width: 830px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  }
  @media (min-width: 831px) and (max-width: 1226px) {
    .container {
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 780px;
    }
  }
  
  @media (max-width: 450px) {
    padding: 10px 0 40px

    h2 {
      font-size: 23px;
      padding: 0 18px;
    }

    > p {
      padding: 0 30px;
      line-height: 1.4;
      br {
        display: none;
      }
    }
  }
`;

export default Trainers;
