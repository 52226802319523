/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Footer from "../components/Footer/Footer";
import Container from "../components/GlobalComponents/Container";
import Main from "../components/Main/Main";
import UiImage from "../components/Image/classOne.jpg";
import Nav from "../components/Navbar/Nav";
import FAQItem from "../components/FAQ/FAQItem";
import { FAQ_DATA } from "../utils/data";

function FAQPage({ data }) {
  return (
    <div className="App">
      {/* <Main
        area={data?.area}
        subtitle={data?.subtitle}
        description={data?.description}
        activePage="about"
        hideInfo
        onlyNavbar
      /> */}
      <Nav activePage="faq" onlyNavbar />

      <Container>
        <div css={styles}>
          <h6>FAQs</h6>

          <h2>Frequently Asked Questions</h2>

          <div>
            {FAQ_DATA?.map((item, index) => (
              <FAQItem
                key={`faq_${index}`}
                question={item?.question}
                answer={item?.answer}
              />
            ))}
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default FAQPage;

const styles = css`
  margin-top: 120px;
  h2 {
    color: #000000;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 1.2px;
    line-height: 34px;
    text-align: center;
    margin: 0 0 45px 0;
  }

  h6 {
    color: #515151;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-align: center;
    margin-bottom: 10px;
  }

  @media (max-width: 1060px) {
  }

  @media (max-width: 450px) {
    h2 {
      font-size: 23px;
      padding: 0 18px;
      margin-bottom: 15px;
    }
  }
`;
