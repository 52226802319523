/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

function LocationCard({ title, desc, onClick, bgImg }) {
  return (
    <div css={styles} onClick={onClick}>
      <div
        style={{ backgroundImage: `url("${bgImg}")` }}
        className="lc-bg-img"
      ></div>

      <div className="lc-content-container">
        <div className="lc-content">
          <h3>{title}</h3>
          {/* <p>{desc}</p> */}
        </div>

        <button onClick={onClick}>VIEW MORE</button>
      </div>
    </div>
  );
}

const styles = css`
  text-align: left;
  cursor: pointer;
  margin: 20px 0 20px 15px;
  background-color: #eef0f3;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  .lc-bg-img {
    width: 100%;
    height: 300px;
    background-color: #f8f9fa;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .lc-content-container {
    height: 100px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px 25px;

    div {
      flex: 1;

      h3 {
        font-size: 26px;
        font-weight: 700;
        color: #2c2d2e;
        margin-bottom: 6px;
      }

      p {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #6f7174;
        line-height: 20px;
        overflow: hidden;
      }
    }

    button {
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
      border-radius: 3px;
      white-space: nowrap;
      padding: 8px 20px;
      background: linear-gradient(
        90deg,
        rgba(254, 147, 86, 1) 9%,
        rgba(254, 100, 14, 1) 100%
      );
      border: none;
      cursor: pointer;
    }
  }

  @media (max-width: 500px) {
    margin: 20px 15px;

    .lc-content-container > div > h3 {
      font-size: 20px;
    }

    .lc-content-container > div > p {
      font-size: 13px;
    }

    .lc-content-container > button {
      font-size: 12px;
    }

    .lc-bg-img {
      height: 240px;
    }
  }
`;

export default LocationCard;
