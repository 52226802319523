import React, { useEffect, useState } from "react";

function ScrollToTopButton() {
  const [showBtn, setShowBtn] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY >= 650) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return showBtn ? (
    <div className="scroll-top-btn" onClick={scrollToTop}>
      <img src="/./images/arrow_up.svg" alt="Scroll To Top" />
    </div>
  ) : (
    <></>
  );
}

export default ScrollToTopButton;
