/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

function TeamMemberCard({ img, name, role, certifications, experience }) {
  return (
    <div css={styles}>
      <div style={{ backgroundImage: `url("${img}")` }} className="lc-bg-img">
        <span>{name}</span>
      </div>

      <div className="details">
        <h4>{role}</h4>

        <p>
          <span>
            <i class="fas fa-trophy"></i> Certifications:
          </span>{" "}
          {certifications}
        </p>
        <p>
          <span>
            <i class="fas fa-award"></i> Experience:
          </span>{" "}
          {experience}
        </p>
      </div>
    </div>
  );
}

export default TeamMemberCard;

const styles = css`
  margin: 20px 10px 20px 0;
  background-color: #fe640e;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  .lc-bg-img {
    width: 100%;
    height: 410px;
    background-color: #f8f9fa;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; 
    position: relative;

    span {
      color: #000000;
      background-color: rgba(256, 256, 256, 0.8);
      padding: 0 10px;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }

  .details {
    padding: 20px;
  }

  img {
    width: 100%;
  }

  h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 10px;
    text-align: left;
  }

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;

    span {
      color: #ffffff !important;
      font-weight: 500;

      i {
        color: #fff;
        margin-right: 4px;
      }
    }
  }

  @media (max-width: 500px) {
    
    margin: 15px 10px 15px 10px;
  }

  @media (max-width: 450px) {
  }
`;
