import React from "react";

export default function ModalCard(props) {
  return (
    <div className="modal-overlay" style={{display: props.open ? 'flex' : 'none'}}>
      <div className="modal-content">
        <button style={btnClose} onClick={props.handleClose}>
          &times;
        </button>

        {props.children}
      </div>
    </div>
  );
}

const btnClose = {
  background: "transparent",
  position: "absolute",
  top: "20px",
  right: "25px",
  width: "40px",
  height: "40px",
  fontSize: "28px",
  opacity: 1,
  borderRadius: "50px",
  border: "none",
  zIndex: "50 !important",
  cursor: "pointer",
  "&:hover": {
    background: "#000000",
  },
};
