/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Link from "./Link";
import { useNavigate } from "react-router-dom";

const LinksContainer = ({ hidden, activePage }) => {
  const navigate = useNavigate();
  return (
    <div css={styles} className={(hidden ? "hidden" : "") + " linksContainer"}>
      {activePage === "home" ? (
        <>
          <Link name="HOME" linkTo="#home" />
          <Link name="BRANCHES" linkTo="#branches" />
          <div
            className="link-btn"
            style={{ color: activePage === "about" ? "#fe640e" : "#ffffff" }}
            onClick={() => {
              navigate("/about-us");
            }}
          >
            ABOUT US
          </div>
          <Link name="FAQ" linkTo="#faq" />
        </>
      ) : activePage === "about" || activePage === "faq" ? (
        <>
          <div
            className="link-btn"
            style={{ color: "#ffffff" }}
            onClick={() => {
              navigate("/");
            }}
          >
            HOME
          </div>

          <div
            className="link-btn"
            style={{ color: activePage === "about" ? "#fe640e" : "#ffffff" }}
            onClick={() => {
              navigate("/about-us");
            }}
          >
            ABOUT US
          </div>

          {/* <div
            className="link-btn"
            style={{ color: activePage === "faq" ? "#fe640e" : "#ffffff" }}
            onClick={() => {
              navigate("#faq");
            }}
          >
            FAQ
          </div> */}
          
          <Link name="FAQ" linkTo="#faq" color="#ffffff" />
        </>
      ) : (
        <>
          <Link name="ABOUT" linkTo="#trainers" />
          {/* <Link name="CLASSES" linkTo="#ourClasses" /> */}
          <Link name="TIMINGS" linkTo="#schedule" />
          <Link name="GALLERY" linkTo="#gallery" />
          <Link name="CONTACT" linkTo="#contact" />
          {/* <Button text="SIGN UP" /> */}

          {/* <div
            className="link-btn"
            style={{ color: activePage === "faq" ? "#fe640e" : "#ffffff" }}
            onClick={() => {
              navigate("#faq");
            }}
          >
            FAQ
          </div> */}
          
          <Link name="FAQ" linkTo="#faq"  />
        </>
      )}
    </div>
  );
};

const styles = css`
  // width: 100%;
  max-width: 620px;
  display: flex;
  align-items: center;
  gap: 35px;
  justify-content: space-between;

  .link-btn {
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    transition: color 600ms ease-in-out;
    &:first-of-type,
    &:hover {
      color: #fe640e;
    }
  }

  @media (max-width: 1000px) {
    max-width: 100%;
    padding: 0 30px 20px 30px;
    flex-direction: column;
    align-items: flex-start;
    opacity: 1;
    position: absolute;
    left: 0;
    top: 70px;
    background: rgba(35, 45, 57, 0.8);
    transition: top 1100ms ease-in-out, opacity 1100ms ease-in-out;
    &.hidden {
      left: 0;
      top: -500px;
      opacity: 0;
    }
    .btn {
      width: 100%;
      text-align: center;
      padding: 16px;
    }
  }
`;

export default LinksContainer;
