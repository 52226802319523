import React from "react";
import { useState } from "react";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import FooterNew from "../components/Footer/FooterNew";
import FooterAbout from "../components/FooterAbout/FooterAbout";
import ImageGallery from "../components/Gallery/ImageGallery";
import Main from "../components/Main/Main";
import StartTrialButton from "../components/Main/StartTrialButton";
import Member from "../components/Member/Member";
import ModalCard from "../components/ModalCard";
import OurTeam from "../components/OurTeam/OurTeam";
import Schedule from "../components/Schedule/Schedule";
import ScrollToTopButton from "../components/ScrollToTopButton";
import Trainers from "../components/Trainers/Trainers";
import YTVideoEmbed from "../components/YTVideoEmbed/YTVideoEmbed";

function BranchDetails({ data }) {
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="App">
      <Main
        sectionBgImg={data?.bgImg}
        area={data?.area}
        subtitle={data?.subtitle}
        description={data?.description}
        address={data?.address}
        contactNumber1={data?.contactNumber1}
        contactNumber2={data?.contactNumber2}
      />

      <StartTrialButton
        hideBanner
        contactNumber1={data?.contactNumber1}
        openForm={() => {
          setShowForm(true);
        }}
      />

      {data?.ytVideoLink && (
        <YTVideoEmbed url="https://www.youtube.com/embed/4kevhozw_UU" />
      )}

      {data?.trainers && <OurTeam trainers={data?.trainers} />}

      {data?.galleryImgs && <ImageGallery galleryImgs={data?.galleryImgs} />}

      {/* <GymProgram /> */}
      <Trainers
        trainingDetails={data?.trainings}
        description={data?.description}
      />

      {/* <Classes /> */}
      <Schedule timing={data?.businessHours} />

      {/* <Member
        onBtnClick={() => {
          setShowForm(true);
        }}
      /> */}

      <Contact
        mapsrc={data?.mapsrc}
        address={data?.address}
        contactNumber1={data?.contactNumber1}
        contactNumber2={data?.contactNumber2}
      />

      {/* <FooterAbout />

      <Footer /> */}

      <FooterNew />

      <ScrollToTopButton />

      <ModalCard
        open={showForm}
        handleClose={() => {
          setShowForm(false);
        }}
      >
        <iframe
          width="100%"
          height="600"
          src="https://www.yoactiv.com/widenq.aspx?adminid=y2FvcFtxxlokAMCOLflRKA==&leadsrc=9"
          frameborder="0"
        ></iframe>
      </ModalCard>
    </div>
  );
}

export default BranchDetails;
