/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import Slider from "react-slick";
import CountUp from "react-countup";
import React, { useRef } from "react";
import useOnScreen from "../../hook/useOnScreen";

function ClientTransformations() {
  const counterRef = useRef(null);
  const isVisible = useOnScreen(counterRef);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const DATA = [
    {
      id: "01",
      img: "/./images/transformations/img1.jpeg",
    },
    {
      id: "02",
      img: "/./images/transformations/img2.jpeg",
    },
    {
      id: "03",
      img: "/./images/transformations/img3.jpeg",
    },
    {
      id: "04",
      img: "/./images/transformations/img4.jpeg",
    },
    {
      id: "05",
      img: "/./images/transformations/img6.jpeg",
    },
    {
      id: "06",
      img: "/./images/transformations/img7.jpeg",
    },
    {
      id: "07",
      img: "/./images/transformations/img8.jpeg",
    },
    {
      id: "08",
      img: "/./images/transformations/img9.jpeg",
    },
    {
      id: "09",
      img: "/./images/transformations/img11.jpeg",
    },
    {
      id: "10",
      img: "/./images/transformations/img12.jpeg",
    },
    {
      id: "11",
      img: "/./images/transformations/img13.jpeg",
    },
  ];

  return (
    <div css={styles}>
      <h2>
        CLIENT <span>TRANSFORMATIONS</span>
      </h2>

      <div className="content">
        <Slider {...settings}>
          {DATA.map((item, index) => {
            return (
              <div key={"client_transform" + index}>
                <img className="transfrom-img" src={item.img} alt="" />
              </div>
            );
          })}
        </Slider>
      </div>

      <h3 ref={counterRef}>CLIENTS TRANSFORMED</h3>

      {isVisible ? (
        <CountUp className="counter" end={2000} suffix="+" />
      ) : (
        <></>
      )}
    </div>
  );
}

export default ClientTransformations;

const styles = css`
  /* width: 100%; */
  text-align: center;
  /* margin: 0 0 50px 0px; */

  border-radius: 10px;
  padding: 25px 0 20px 0;
  margin: 35px 25px 10px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  h2 {
    color: #232d39;
    font-size: 26px;
    font-weight: 900;
    line-height: 32px;
  }

  h3 {
    color: #232d39;
    font-size: 18px;
    font-weight: 900;
    line-height: 32px;
    margin: 50px 0 10px;
  }

  span {
    color: #fe640e;
  }

  .content {
    margin: 0;
  }

  .transfrom-img {
    width: 90%;
    object-fit: cover;
    margin: 40px 18px;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
  }

  .counter {
    font-size: 26px;
    font-weight: 600;
  }

  @media (max-width: 500px) {
    margin: 35px 25px 40px 25px;

    h2 {
      font-size: 23px;
      padding: 0 18px;
    }

    .transfrom-img {
      margin: 20px 18px;
      height: 350px;
    }

    .content {
      margin: 20px 0;
    }
  }
`;
