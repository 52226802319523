/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const Overlay = () => <div css={styles} className="overlay"></div>;

const styles = css`
  height: 90vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(35, 45, 57, 0.4);

  
  @media (max-width: 500px) {
    // min-height: 70vh;
    // max-height: 70vh;
    
    min-height: 58vh;
    max-height: 58vh;
  }
`;

export default Overlay;
