/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import FooterAbout from "../FooterAbout/FooterAbout";

function FooterNew() {
  return (
    <section css={styles}>
      <footer>
        <div className="footer-content">
          <div className="site-info">
            <img src="/./images/gof-footer-logo.png" alt="Game on Fitness" />
            <p>Bangalore, Karnataka</p>
            <p>info@gameonfitness.in</p>
          </div>

          <div className="footer-links">
            <ul>
              <li>
                <a href="#">HOME</a>
              </li>
              <li>
                <a href="#branches">BRANCHES</a>
              </li>
              <li>
                <a href="/about-us">ABOUT US</a>
              </li>
              <li>
                <a href="#faq">FAQ</a>
              </li>
            </ul>
          </div>

          <div className="social-icons">
            <a
              href="https://m.facebook.com/112450367880632/"
              target="_blank"
              className="icon-fb"
            >
              <i class="fab fa-facebook-square"></i>
            </a>

            <a
              href="https://www.instagram.com/gameonfitness_/"
              target="_blank"
              className="icon-insta"
            >
              <i class="fab fa-instagram"></i>
            </a>

            <a
              href="https://www.youtube.com/channel/UCBNqbUliZ_vyJhpt-egzUFw"
              target="_blank"
              className="icon-youtube"
            >
              <i class="fab fa-youtube"></i>
            </a>
          </div>
        </div>

        <div className="copyright-bar">
          <p>
            Copyright © 2023{" "}
            <a href="#" target="_blank">
              GAME ON FITNESS
            </a>
          </p>
        </div>
      </footer>
    </section>
  );
}

const styles = css`
  text-align: center;
  padding: 45px 60px 30px;
  background-image: linear-gradient(
    to right bottom,
    #062e33,
    #14383d,
    #204247,
    #2c4d51,
    #38585c
  );

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .site-info {
    img {
      width: 220px;
    }

    p {
      font-size: 13px;
      font-weight: 500;
      color: #fff;
      line-height: 27px;
      text-align: center;
      margin-left: 15px;
    }
  }

  .footer-links {
    ul {
      display: flex;
      align-items: center;
      gap: 25px;
    }

    li {
      list-style: none;
      text-align: left;
      margin-bottom: 8px;

      a {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        text-decoration: none;
      }

      a:hover {
        color: rgba(256, 256, 256, 0.5);
        text-decoration: underline;
      }
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 15px;

    a {
      font-size: 24px;
      text-decoration: none;
    }
  }

  .icon-fb {
    color: #fff;
  }
  .icon-insta {
    color: #fff;
  }
  .icon-youtube {
    color: #fff;
  }

  .copyright-bar {
    text-align: center;
    margin-top: 40px;
    p {
      font-size: 15px;
      color: #fff;
      a {
        text-decoration: none;
        color: #fff;
        font-weight: 500;
        &:hover {
          color: rgba(256, 256, 256, 0.5);
        }
      }
    }
  }

  @media (max-width: 500px) {
    padding: 45px 20px 30px;

    .footer-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .footer-links {
      margin-top: 20px;
      li {
        a {
          font-size: 13px;
        }
      }
    }

    
  .copyright-bar {
    p {
      font-size: 14px;
      color: #fff;
      a {
        text-decoration: none;
        color: #fff;
        font-weight: 500;
        &:hover {
          color: rgba(256, 256, 256, 0.5);
        }
      }
    }
  }
  }
`;

export default FooterNew;
