/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

const Button = ({ text, handleClick }) => (
  <button css={styles} onClick={handleClick} className="btn">
    {text}
  </button>
);

const styles = css`
  text-decoration: none;
  display: inline-block;
  background: linear-gradient(90deg, rgba(254,147,86,1) 9%, rgba(254,100,14,1) 100%);
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  padding: 12px 14px;
  transition: background 500ms ease-in-out;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background: linear-gradient(180deg, rgba(254,147,86,1) 9%, rgba(254,100,14,1) 100%);
  }
`;

export default Button;
