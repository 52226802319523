/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";

const Info = ({
  area,
  subtitle,
  description,
  address,
  contactNumber1,
  contactNumber2,
}) => (
  <div css={styles} className="info">
    {area && subtitle ? (
      <>
        <p className="area">{area ? area : "WORK HARDER, GET STRONGER"}</p>

        <p className="subtitle">
          <i className="fas fa-building"></i>
          {address ? address : "WORK HARDER, GET STRONGER"}
        </p>

        {contactNumber1 && (
          <p className="subtitle call-btn">
            <i className="fas fa-phone"></i>

            <a href={`#`}>{"+91 " + contactNumber1}</a>
          </p>
        )}
      </>
    ) : (
      <>
        <p>WORK HARDER, GET STRONGER</p>
        <h1>
          EASY WITH OUR <span>GYM</span>
        </h1>
        {/* <Button text="BECOME A MEMBER" /> */}
      </>
    )}
  </div>
);

const styles = css`
  width: 100%;
  max-width: 900px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  color: #fff;

  .area {
    font-size: 48px;
    line-height: 1;
    font-weight: 600;
    color: #ed563b
    letter-spacing: 1.2px;
  }

  .fa-phone {
    rotate: 95deg;
  }

  .call-btn{
    cursor: pointer;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 400;
    margin: 18px 0 0 0;
    line-height: 34px;

    i {
      color: #ffffff;
      margin-right: 10px;
    }

    a {
      color: #ffffff;
      text-decoration: none;
    }
  }

  p {
    font-size: 30px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1.2px;
  }
  h1 {
    font-size: 90px;
    line-height: 1;
    font-weight: 900;
    margin: 36px 0;
    span {
      color: #ed563b;
    }
  }
  .btn {
    padding: 14px 16px;
  }
  @media (max-width: 1000px) {
    h1 {
      font-size: 42px;
    }
  }
  
  @media (max-width: 500px) {
    top: 38%;
    p {
      font-size: 22px;
      padding: 0 20px
    }

    h1 {
      font-size: 36px;
      padding: 0 20px;
      margin: 30px 0;
    }

    .area {
      font-size: 26px;
    }

    .subtitle {
      font-size: 17px;
      padding: 0 20px
    }
  }
`;

export default Info;
