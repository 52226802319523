import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import { useEffect } from "react";
import {
  AKSHAYANAGAR,
  AREKERE,
  BTM_LAYOUT_1,
  BTM_LAYOUT_2,
  JP_NAGAR,
  SARJAPURA,
  VIJAYA_BANK_LAYOUT,
  WILSON_GARDEN,
} from "./utils/data";
import BranchDetails from "./pages/BranchDetails";
import AboutUs from "./pages/AboutUs";
import FAQPage from "./pages/FAQPage";

const routes = (
  <Router forceRefresh={true}>
    <ScrollToTop>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route
          path="/btm-layout-1"
          exact
          element={<BranchDetails data={BTM_LAYOUT_1} />}
        />
        <Route
          path="/btm-layout-2"
          exact
          element={<BranchDetails data={BTM_LAYOUT_2} />}
        />
        <Route
          path="/sarjapura"
          exact
          element={<BranchDetails data={SARJAPURA} />}
        />
        <Route
          path="/arekere"
          exact
          element={<BranchDetails data={AREKERE} />}
        />
        <Route
          path="/vijaya-bank-layout"
          exact
          element={<BranchDetails data={VIJAYA_BANK_LAYOUT} />}
        />
        <Route
          path="/jp-nagar"
          exact
          element={<BranchDetails data={JP_NAGAR} />}
        />
        <Route
          path="/wilson-garden"
          exact
          element={<BranchDetails data={WILSON_GARDEN} />}
        />
        <Route
          path="/akshayanagar"
          exact
          element={<BranchDetails data={AKSHAYANAGAR} />}
        />
        <Route path="/about-us" exact element={<AboutUs />} />
        {/* <Route path="/faq" exact element={<FAQPage />} /> */}
      </Routes>
    </ScrollToTop>
  </Router>
);

function App() {
  useEffect(() => {
    console.log = ()=>{}
  }, []);
  return <div>{routes}</div>;
}

export default App;
