/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState } from "react";

function FAQItem({ question, answer }) {
  const [showAnswer, setShowAnswer] = useState(false);

  const handleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <div css={styles}>
      <div className="question-container">
        <h3>{question}</h3>
        <button onClick={handleShowAnswer}>
          <i class={`fas ${showAnswer ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
        </button>
      </div>
      {showAnswer && <p>{answer}</p>}
    </div>
  );
}

export default FAQItem;

const styles = css`
  /* background-color: #ffffff; */
  padding: 15px;
  /* box-shadow: 0px 0px 41px rgba(0, 0, 0, 0.05); */
  /* border-radius: 4px; */
  margin-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;

  h3 {
    flex: 1;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 26px;
  }

  p {
    color: #515151;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 26px;
    margin-top: 12px;
    white-space: pre-line
  }

  .question-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    width: 35px;
    height: 35px;
    font-size: 14px;
    background-color: #f3f3f3;
    border: 2px solid #f3f3f3;
    outline: none;
    border-radius: 50px;
  }

  @media (max-width: 450px) {
    h3 {
      font-size: 14.5px;
    }
  }
`;
