/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useRef, useState } from "react";
import Slider from "react-slick";

function Testimonial() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const DATA = [
    {
      id: "01",
      img: "/./images/trainerOne.jpg",
      username: "Mike Wheeler",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
    },
    {
      id: "02",
      img: "/./images/trainerTwo.jpg",
      username: "Dustin Henderson",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
    },
    {
      id: "03",
      img: "/./images/trainerThree.jpg",
      username: "Lucas Sinclair",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
    },
    {
      id: "04",
      img: "/./images/trainerOne.jpg",
      username: "Lucas Sinclair",
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
    },
  ];

  const videos = [
    {
      url: "https://www.youtube.com/embed/yM9mXFV63wQ",
    },
    {
      url: "https://www.youtube.com/embed/Re4vbcWbEWQ",
    },
    {
      url: "https://www.youtube.com/embed/mFRygDrjXN4",
    },
  ];

  const videoList = useRef(videos);
  const slider = useRef(null);

  const onSlideNext = () => {
    slider.current.slickNext();
  };

  const onSlidePrev = () => {
    slider.current.slickPrev();
  };

  return (
    <div css={styles}>
      <h2>
        HEAR FROM <span>OUR CLIENTS</span>
      </h2>
      {/* <Icon /> */}

      <div className="content">
        <Slider
          {...settings}
          ref={slider}
          beforeChange={(cur, next) => {
            setCurrentIndex(next);
          }}
        >
          {videoList.current.map((item, index) => {
            return (
              <div key={"testimonial_" + index}>
                {/* <TestimonialCard
                  profileImg={item?.img}
                  username={item?.username}
                  content={item?.content}
                /> */}
                {/* <Vid
                  key={"testimonial_count" + index}
                  vidRef={(ref) => refs.current.push(ref)}
                  id={index}
                  src={item.url}
                  onPlay={() => playVideo(index)}
                /> */}

                <div>
                  <iframe
                    height="315"
                    src={item.url}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            );
          })}
        </Slider>

        <button className="left-arrow" onClick={onSlidePrev}>
          <i class="fas fa-chevron-left"></i>
        </button>
        <button className="right-arrow" onClick={onSlideNext}>
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
}

export default Testimonial;

const styles = css`
  /* width: 100%; */
  text-align: center;
  /* margin: 0 0 65px 0px; */
  position: relative;
  
  border-radius: 10px;
  padding: 25px 0 20px 0;
  margin: 35px 25px 10px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  h2 {
    color: #232d39;
    font-size: 26px;
    font-weight: 900;
    line-height: 32px;
    margin-bottom: 20px;
  }

  span {
    color: #fe640e;
  }

  .content {
    margin: 20px 0 0 0;
  }

  button {
    width: 40px;
    height: 40px;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: #fe640e;
    color: #ffffff;
    border-radius: 50%;
    border: none;
    position: absolute;
  }

  .left-arrow {
    top: 55%;
    left: 10px;
  }

  .right-arrow {
    top: 55%;
    right: 10px;
  }

  iframe {
    margin: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 800px) {
    button {
      display: flex;
    }
  }

  @media (max-width: 500px) {
    padding-bottom: 40px;
    margin: 35px 25px 40px 25px;
    
    h2 {
      font-size: 23px;
      padding: 0 18px;
    }
  }
`;
