/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import Slider from "react-slick";
import TeamMemberCard from "./TeamMemberCard";

function OurTeam({trainers}) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div css={styles}>
      <h2>
        OUR <span>TEAM</span>
      </h2>
      {/* <Icon /> */}

      <div className="content">
        <Slider {...settings}>
          {trainers?.map((item, index) => {
            return (
              <TeamMemberCard
                key={"our_trainer" + index}
                img={item?.img}
                name={item?.name}
                role={item?.role}
                certifications={item?.certifications}
                experience={item?.experience}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default OurTeam;

const styles = css`
  /* width: 100%; */
  text-align: center;
  /* margin: 50px 0px 30px 0; */
  
  border-radius: 10px;
  padding: 25px 0 20px 0;
  margin: 35px 25px 10px 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  h2 {
    color: #232d39;
    font-size: 26px;
    font-weight: 900;
    line-height: 32px;
  }

  span {
    color: #fe640e;
  }

  .content {
    margin: 10px 10px 0 10px;
  }

  .transfrom-img {
    width: 90%;
    height: 500px;
    object-fit: contain;
    margin: 0 18px;
  }

  @media (max-width: 500px) {
    /* margin: 0 0 65px 0; */

    padding-bottom: 30px;
    margin: 35px 25px 40px 25px;

    h2 {
      font-size: 23px;
      padding: 0 18px;
      margin-bottom: 0;
    }

    .transfrom-img {
      height: 350px;
    }

    .content {
      margin: 10px 0 20px 0;
    }
  }
`;
