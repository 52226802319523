import React from "react";

function WhatsappFAB() {
  return (
    <div
      //   style={{
      //     backgroundColor: "#25D366",
      //     padding: "13px",
      //     position: "fixed",
      //     left: "38px",
      //     bottom: "38px",
      //     borderRadius: "45px 45px 45px 6px",
      //   }}

      className="whatsapp-fab"
    >
      <a href="https://wa.me/9148974009" target="_blank">
        <i className="fab fa-whatsapp whatsapp-fab-icon"></i>
      </a>
    </div>
  );
}

export default WhatsappFAB;
